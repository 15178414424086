// DELOITTE THEME
// ==============

// PRIMARY COLORS
$White: #ffffff;
$Black: #000000;
$Red: #da291c;
$Red2: #da291c0a;
$Orange: #ed8b00;
$Orange2: #ed8b000a;
$Yellow: #ffcd00;
$Yellow2: #ffcd000a;
$DeloitteGreen: #86bc25;
$Green4: #43b02a;
$AccessibleGreen: #26890d;
$AccessibleTeal: #0d8390;
$AccessibleBlue: #007cb0;

// SECONDARY COLORS
$Green1: #e3e48d;
$Green2: #c4d600;
$Green5: #009a44;
$Green7: #2c5234;
$Blue1: #a0dcff;
$Blue2: #62b5e5;
$Blue3: #00a3e0;
$Blue4: #0076a8;
$Blue5: #005587;
$Blue6: #012169;
$Blue7: #041e42;
$Teal1: #ddefe8;
$Teal2: #9dd4cf;
$Teal3: #6fc2b4;
$Teal4: #00abab;
$Teal5: #0097a9;
$Teal6: #007680;
$Teal7: #004f59;
$CoolGray1: #f4f4f4;
$CoolGray2: #d0d0ce;
$CoolGray4: #bbbcbc;
$CoolGray6: #a7a8aa;
$CoolGray7: #97999b;
$CoolGray9: #75787b;
$CoolGray10: #63666a;
$CoolGray11: #53565a;
$CoolGray12: #a0a0a0;

// CSRD SPECIFIC
$Score_Pass: $AccessibleGreen;
$Score_Partial: $Green2;
$Score_Fail: $Red;
$Score_Open: $CoolGray4;

$Status_Open: $CoolGray4;
$Status_In-progress: $Blue3;
$Status_In-review: $Yellow;
$Status_Rejected: $Orange;
$Status_Approved: $Teal3;
