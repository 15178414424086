@use "@shared-angular-themes/deloitte-angular-material.scss";
@use "@shared-colors/deloitte-colors.scss" as colors;
@use "@shared-fonts/typography.scss";
@import "material-icons/iconfont/material-icons.css";

body {
  font:
    400 14px/20px "Open Sans",
    sans-serif;
  background-color: colors.$CoolGray1;
}
